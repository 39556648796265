import React from 'react';

import logoMaskImage from '../images/logo_mask.png';

const Splash = () => {
    return (
        <div className='splash'>
            <div className='hero'>
                <div className="background"></div>
                <img src={logoMaskImage} alt='Large Wisteria logo' />
            </div>
            <div className='column'>
                <h2>Truly automated turf cutting for your campaign</h2>
                <p>Wisteria helps speed up your campaign's canvassing efforts and connect to voters faster by removing the work of dividing voters into groups for volunteers to talk to.</p>
                <p>Wisteria integrates with NGP VAN and other campaign management platfrorms, and uses a specialized algorithm that considers walkability and obstructions like highways, awkward subdivision layouts, rivers, and cliffs. This enables calculation of walking routes that candidates and staff can follow to speak to their constituents. Instead of wasting time and money manually drawing polygons on a map, your staff can pick up their clipboards, tie their laces, and get out the vote.</p>
                <a className='button' href='mailto:me@erikboesen.com?subject=Campaign interested in cutting turf with Wisteria'>Get Access</a>
            </div>
        </div>
    );
};

export default Splash;
