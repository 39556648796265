import React from 'react';

const About = () => {
    return (
        <div className='page about'>
            <div className='column'>
                <h2>Automating turf cutting</h2>
                <p>Wisteria brings the process of turf cutting, or separating voter databases into walkable areas to delegate to volunteers for door knocking, into the 21st century.</p>
                <p>Despite the sophistication of campaign technology, turf cutting through existing software platforms has previously remained incredibly laborious and manual. Industry standard half-solutions, which invariably rely on clustering by latitude and longitude, can't capture the nuances of the streetscape, and tend to yield impractical paths that aren't actually efficient for a volunteer to walk.</p>
                <p>Wisteria uses a novel set of algorithms to automatically compute ideal walking paths for campaign staff to follow, without manual input, saving invaluable time and money for your campaign.</p>
                <p>Wisteria is a product of Wisteria Software, founded by <a href="https://erikboesen.com">Erik Boesen</a> and <a href="https://davidjacksonfoster.com">David Foster</a>.</p>
            </div>
        </div>
    );
};

export default About;
