import { useRef, useEffect, useState } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';

import About from './screens/About';
import Splash from './screens/Splash';

import VotersMap from './components/VotersMap';

import logoImage from './images/logo.png'

import 'mapbox-gl/dist/mapbox-gl.css';
import './App.css';

function App() {
    const currentYear = new Date().getFullYear();

    return (
        <Router>
            <header>
                <a href="/" id='logo'>
                    <img src={logoImage} />
                    <h1>Wisteria</h1>
                </a>
                <nav>
                    <ul>
                        <li><a href="about">About</a></li>
                    </ul>
                </nav>
            </header>
            <main>
                <Routes>
                    <Route path="" element={<Splash />} />
                    {/*<Route path="login" element={token ? <Navigate to="/" /> : <Login />} />*/}
                    <Route path="map" element={<VotersMap />} />
                    <Route path="about" element={<About />} />
                </Routes>
                <footer>
                    <p>&copy; Copyright {currentYear} Wisteria Software.</p>
                </footer>
            </main>
        </Router>
    );
}

export default App;
